export default [
  'all',
  'another',
  'any',
  'anybody',
  'anyone',
  'anything',
  'as',
  'aught',
  'both',
  'each',
  'either',
  'enough',
  'everybody',
  'everyone',
  'everything',
  'few',
  'he',
  'her',
  'hers',
  'herself',
  'him',
  'himself',
  'his',
  'I',
  'idem',
  'it',
  'its',
  'itself',
  'many',
  'me',
  'mine',
  'most',
  'my',
  'myself',
  'naught',
  'neither',
  'nobody',
  'none',
  'nothing',
  'nought',
  'one',
  'other',
  'others',
  'ought',
  'our',
  'ours',
  'ourself',
  'ourselves',
  'several',
  'she',
  'some',
  'somebody',
  'someone',
  'something',
  'somewhat',
  'such',
  'suchlike',
  'that',
  'thee',
  'their',
  'theirs',
  'theirself',
  'theirselves',
  'them',
  'themself',
  'themselves',
  'there',
  'these',
  'they',
  'thine',
  'this',
  'those',
  'thou',
  'thy',
  'thyself',
  'us',
  'we',
  'what',
  'whatever',
  'whatnot',
  'whatsoever',
  'whence',
  'where',
  'whereby',
  'wherefrom',
  'wherein',
  'whereinto',
  'whereof',
  'whereon',
  'wherever',
  'wheresoever',
  'whereto',
  'whereunto',
  'wherewith',
  'wherewithal',
  'whether',
  'which',
  'whichever',
  'whichsoever',
  'who',
  'whoever',
  'whom',
  'whomever',
  'whomso',
  'whomsoever',
  'whose',
  'whosever',
  'whosesoever',
  'whoso',
  'whosoever',
  'ye',
  'yon',
  'yonder',
  'you',
  'your',
  'yours',
  'yourself',
  'yourselves'
]